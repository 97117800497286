import { FormEvent, useContext, useEffect, useState } from "react"
import { payloadCadastrarComunicado, payloadAtualizarComunicado, modeloContatoComunicadoListar, payloadAdicionarContato, modeloCandidatoContatoComunicadoListar, modeloCadastroComunicadoListar } from "../../interfaces/atendimento"
import { adicionarContato, atualizarComunicado, buscarTags, cadastrarComunicado, cancelarDestinatario, listarContatos } from "../../services/Atendimento"
import { toast } from "react-toastify"
import { ModalEstadoContext } from "../../contexts/ModalEstado";
import { Button, Loader } from "../Styles";
import { criptografarDado } from "../../helpers";
import ModalAvisoCancelar from "../ModalAvisoCancelar";
import Organizacao from "../../middleware/organizacao";
import { IoIosAddCircleOutline } from "react-icons/io";
import ModalAdicionarContato from "../ModalAdicionarContato";
import { FaTrash } from "react-icons/fa";
import DivSituacao from "../DivSituacao";
import "./index.css"

function ModalComunicado({ comunicado }: any) {

  const { atualizarDados, modalExibir, atualizarInformacoes } = useContext(ModalEstadoContext);

  const [processando, setProcessando] = useState(false)

  const [titulo, setTitulo] = useState(comunicado?.titulo)
  const [mensagem, setMensagem] = useState("")
  const [whatsapp, setWhatsapp] = useState<Boolean>(false)
  const [email, setEmail] = useState<Boolean>(false)
  const [grupoTag, setGrupoTag] = useState(comunicado?.grupo || "")
  const [dataEnvio, setDataEnvio] = useState(comunicado?.dataEnvio || "")
  const [gruposOuTags, setGruposOuTags] = useState<any>([])

  const [modalAvisoCancelar, setModalAvisoCancelar] = useState(false)
  const [modalAdicionarContato, setModalAdicionarContato] = useState(false)
  const [contatoSelecionado, setContatoSelecionado] = useState<number | null>(null);
  const [candidatoSelecionado, setCandidatoSelecionado] = useState<number | null>(null);

  const [contatos, setContatos] = useState<modeloContatoComunicadoListar[]>([])
  const [candidatos, setCandidatos] = useState<modeloCandidatoContatoComunicadoListar[]>([])
  const [cadastros, setCadastros] = useState<modeloCadastroComunicadoListar[]>([])

  const handleUpdate = async (e: FormEvent) => {
    e.preventDefault()
    setProcessando(true)
    try {
      const payload: payloadAtualizarComunicado = {
        titulo
      }
      const idComunicado = comunicado.id

      const idComunicadoCript = criptografarDado(idComunicado.toString())

      const resposta = await atualizarComunicado(payload, idComunicadoCript)

      if (resposta.status === 200) {
        setProcessando(false)
        toast.success("Comunicado Atualizado com Sucesso.")
        atualizarDados()
        return modalExibir("")
      }
    } catch (error: any) {

      setProcessando(false)

      if (error.response.status === 409) {
        setProcessando(false)
        toast.info("Não é possivel atualizar um Comunicado com situação de Cancelado ou Enviado.")
        return modalExibir("")
      }

      toast.error("Ocorreu um erro ao tentar Atualizar o Comunicado.")
    }
  }

  const handleCancelarComunicado = async () => {
    try {
      const payload = {
        situacao: "Cancelado"
      }

      const idComunicado = comunicado.id

      const idComunicadoCript = criptografarDado(idComunicado.toString())

      const resposta = await atualizarComunicado(payload, idComunicadoCript)

      if (resposta.status === 200) {
        setProcessando(false)
        atualizarDados()
        toast.success("Comunicado Cancelado com Sucesso.")
        return modalExibir("")
      }
    } catch (error: any) {
      setProcessando(false)
      if (error.response.status === 409) {
        setProcessando(false)
        toast.info("Não é possivel cancelar um Comunicado com situação de Cancelado ou Enviado.")
        return modalExibir("")
      }
      toast.error("Ocorreu um erro ao tentar Cancelar o Comunicado.")
    }
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    if (!email && !whatsapp) return toast.info("É necessário que pelo menos 1 canal de envio esteja habilitado.")
    setProcessando(true)
    try {
      const payload: payloadCadastrarComunicado = {
        titulo, mensagem, whatsapp, email, grupo: grupoTag, dataEnvio: new Date(dataEnvio).toISOString()
      }

      const resposta = await cadastrarComunicado(payload)

      if (resposta.status === 201) {
        atualizarDados()
        setProcessando(false)
        toast.success("Comunicado Criado com Sucesso.")
        return modalExibir("")
      }
    } catch (error) {
      setProcessando(false)
      toast.error("Ocorreu um erro ao tentar Cadastrar um Novo Comunicado.")
    }
  }

  const handleAdicionarContato = async (e: FormEvent) => {
    e.preventDefault()
    setProcessando(true)
    setCandidatoSelecionado(null);

    if (!contatoSelecionado && !candidatoSelecionado) {
      toast.error("Selecione um contato antes de adicionar.");
      setProcessando(false);
      return;
    }

    try {

      const payload: payloadAdicionarContato = {
        idContato: contatoSelecionado || "",
        idCandidato: candidatoSelecionado || ""
      }

      const idComunicado = comunicado.id

      const idComunicadoCript = criptografarDado(idComunicado.toString())

      const resposta = await adicionarContato(payload, idComunicadoCript)
      if (resposta.status === 201) {
        atualizarDados()
        setProcessando(false)
        toast.success("Contato Incluido com Sucesso.")
        return
      }
    } catch (error: any) {
      if (error.response.status === 409) {
        setProcessando(false)
        toast.info("Não é possível adicionar um destinatário para um comunicado cancelado ou enviado")
        return modalExibir("")
      }
      setProcessando(false)
      toast.error("Ocorreu um erro ao tentar Cadastrar um Novo Comunicado.")
    }
  }

  const handleDeleteContato = async (e: FormEvent, destinatario: any) => {
    e.preventDefault()
    setProcessando(true)

    const idDestinatarioCript = criptografarDado(destinatario.id.toString())
    try {
      const resposta = await cancelarDestinatario(idDestinatarioCript)

      if (resposta.status === 200) {
        atualizarDados()
        setProcessando(false)
        toast.success("Contato cancelado com Sucesso.")
        return
      }
    } catch (error: any) {
      if (error.response.status === 409) {
        setProcessando(false)
        toast.info("Não é possivel cancelar, pois o Comunicado foi Enviado.")
        return modalExibir("")
      }
      setProcessando(false)
      toast.error("Ocorreu um erro ao tentar Cancelar o Comunicado.")
    }
  }

  const contatosUnicos = contatos.reduce((acc: any[], item: any) => {
    const existeContato = acc.some(obj => obj.idContato === item.idContato);

    if (!existeContato) {
      acc.push(item);
    }

    return acc;
  }, []);

  const candidatosUnico = candidatos.reduce((acc: any[], item: any) => {
    const existeCandidatos = acc.some(obj => obj.idCandidato === item.idCandidato);
    if (!existeCandidatos) {
      acc.push(item);
    }

    return acc;
  }, []);

  const cadastroUnico = cadastros.reduce((acc: any[], item: any) => {
    const existeCadastro = acc.some(obj => obj.idCadastro === item.idCadastro);
    if (!existeCadastro) {
      acc.push(item);
    }

    return acc;
  }, []);

  useEffect(() => {
    const listarContatosDestinatarios = async () => {
      if (!comunicado?.id) return;
      setProcessando(true)
      try {
        const idComunicado = comunicado.id.toString()

        const idComunicadoCript = criptografarDado(idComunicado)

        const resposta = await listarContatos(idComunicadoCript);
        setContatos(resposta?.data || []);

        const contato = resposta?.data.filter((item: any) => item.contato !== null);
        const candidato = resposta?.data.filter((item: any) => item.bancoTalento !== null);
        const cadastro = resposta?.data.filter((item: any) => item.cadastro !== null);

        setCandidatos(candidato)
        setContatos(contato)
        setCadastros(cadastro)

        setProcessando(false)

      } catch (error) {
        setProcessando(false)
        toast.error("Ocorreu um erro ao tentar Listar os Destinatarios.")
      }
    }
    listarContatosDestinatarios()
    
  }, [comunicado, atualizarInformacoes])

  useEffect(() => {
    const buscarTodasTags = async () => {
      setProcessando(true)
      try {
        const resposta = await buscarTags()
        const tags = resposta.data

        const gruposTag = tags.map((tag: any) => tag.nome)
        const gruposTagUnicos = Array.from(new Set(gruposTag));
        setGruposOuTags(gruposTagUnicos)

        setProcessando(false)
      } catch (error) {
        setProcessando(false)

      }
    }
    buscarTodasTags()
  }, [])

  return (
    <div className="fundoModal">
      <section className={comunicado ? 'containerModalNovo' : 'containerModalCentro'}>
        <form className="formModal" onSubmit={comunicado ? handleUpdate : handleSubmit}>
          <section>
            <span className='novoContainerTitulo'>
              <h1>{comunicado ? "Atualizar Comunicado" : "Cadastrar Comunicado"}</h1>
            </span>
          </section>
          <section className='opcoesFiltros'>
            <section>
              <span>
                <label htmlFor="">Título</label>
                <input type="text" name="" defaultValue={comunicado?.titulo} placeholder="Título do Comunicado" maxLength={100} onChange={(e) => setTitulo(e.target.value)} required />
              </span>
            </section>
            <section className="inputs-modal-comunicado">
              <span>
                <label htmlFor="">Enviar por Tag/Grupo:</label>
                <select value={grupoTag} onChange={(e) => setGrupoTag(e.target.value)} required={!comunicado} disabled={!!comunicado}>
                  <option value="" disabled>Selecione uma Tag/Grupo</option>
                  <option value="cliente">Clientes</option>
                  <option value="responsável">Responsáveis</option>
                  <option value="colaborador">Colaboradores</option>
                  <option value="fornecedor">Fornecedores</option>
                  <Organizacao organizacao={[`${process.env.REACT_APP_ORGANIZACAO_HG}`]}>
                    <option value="candidatos">Banco de Talentos</option>
                  </Organizacao>
                  {gruposOuTags.map((grupoTag: any, index: number) => (
                    <option value={grupoTag} key={index}>{grupoTag.charAt(0).toUpperCase() + grupoTag.substring(1)}</option>
                  ))}
                </select>
              </span>
              <span >
                <label htmlFor="">Data de Envio</label>
                {comunicado?.dataEnvio ?
                  <input type="text" name="" disabled defaultValue={comunicado?.dataEnvio && new Date(comunicado?.dataEnvio).toLocaleString('pt-BR', {
                    hour: '2-digit',
                    minute: '2-digit',
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                  }).replace(',', ' às ')} />
                  :
                  <input type="datetime-local" name="" onChange={(e) => setDataEnvio(e.target.value)} required />
                }
              </span>
            </section>
            <section>
              <span>
                <label htmlFor="">Mensagem</label>
                <textarea name="" cols={1} rows={8} className="textAreaSobreMim areaTexto" placeholder="Digite a Mensagem" onChange={(e) => setMensagem(e.target.value)} required={!comunicado} disabled={!!comunicado} maxLength={1000} defaultValue={comunicado?.mensagem}></textarea>
              </span>
            </section>
            <section>
              <span>
                <label htmlFor="">Canais de Envio:</label>
                <div className="toggle-switch-div">
                  <div className="toggle-switch">
                    <input className="toggle-input" id="toggle" type="checkbox" onChange={(e) => setWhatsapp(e.target.checked)} disabled={!!comunicado} defaultChecked={comunicado?.whatsapp} />
                    <label className="toggle-label" htmlFor="toggle"></label>
                  </div>
                  <p>Whatsapp</p>
                </div>
                <div className="toggle-switch-div">
                  <div className="toggle-switch">
                    <input className="toggle-input" id="toggleTwo" type="checkbox" onChange={(e) => setEmail(e.target.checked)} disabled={!!comunicado} defaultChecked={comunicado?.email} />
                    <label className="toggle-label" htmlFor="toggleTwo"></label>
                  </div>
                  <p>E-mail</p>
                </div>
              </span>
            </section>
            {comunicado &&
              <section>
                <span>
                  <div className="header-modal">
                    <label htmlFor="">Destinatários</label>
                    {comunicado.situacao === "Agendado" && <IoIosAddCircleOutline fontSize={"23px"} cursor="pointer" onClick={() => setModalAdicionarContato(true)} className="icone-lado-direito" />}

                  </div>
                  <span className="container-contatos">
                    {contatosUnicos.map((contato: any, index: number) => (
                      (contato || contato.bancoTalento) && (
                        <span key={index} className="span-destinatarios">
                          {contato.contato && <p>{contato.contato.nome}</p>}
                          <div>
                            <p><DivSituacao texto={contato.situacao} cor={contato.situacao.toLowerCase()} /></p>
                            {contato.situacao === "Agendado" && comunicado.situacao === "Agendado" && <FaTrash fontSize={"20px"} onClick={(e) => handleDeleteContato(e, contato)} className="cursor-icone" />}
                          </div>
                        </span>
                      )
                    ))}
                    {candidatosUnico.map((candidato: any, index: number) => (
                      (candidato || candidato.bancoTalento) && (
                        <span key={index} className="span-destinatarios">
                          {candidato.bancoTalento && <p>{candidato.bancoTalento.nomeCompleto}</p>}
                          <div>
                            <p>{<DivSituacao texto={candidato.situacao} cor={candidato.situacao.toLowerCase()} />}</p>
                            {candidato.situacao === "Agendado" && comunicado.situacao === "Agendado" && <FaTrash fontSize={"20px"} onClick={(e) => handleDeleteContato(e, candidato)} className="cursor-icone" />}
                          </div>
                        </span>
                      )
                    )
                    )}
                    {cadastroUnico.map((cadastro: any, index: number) => (
                      (cadastro) && (
                        <span key={index} className="span-destinatarios">
                          {cadastro && <p>{cadastro.cadastro.nomeFantasia}</p>}
                          <div>
                            <p>{<DivSituacao texto={cadastro.situacao} cor={cadastro.situacao.toLowerCase()} />}</p>
                            {cadastro.situacao === "Agendado" && comunicado.situacao === "Agendado" && <FaTrash fontSize={"20px"} onClick={(e) => handleDeleteContato(e, cadastro)} className="cursor-icone" />}
                          </div>
                        </span>
                      )
                    )
                    )}
                  </span>
                </span>
              </section>
            }
          </section>
          <span id="botoesContainer">
            <Button type='button' cor='cancelar' isLoading={processando} onClick={() => modalExibir("")}>Fechar</Button>
            {comunicado && comunicado.situacao === 'Agendado' && <Button type='button' cor='cancelarComunicado' isLoading={processando} onClick={() => setModalAvisoCancelar(true)}>Cancelar Comunicado</Button>}
            <Button type='submit' cor='salvar' isLoading={processando}>
              {!processando && !comunicado && 'Cadastrar'}
              {!processando && comunicado && 'Atualizar Dados'}
              {processando && <Loader isLoading={processando} />}
            </Button>
          </span>
        </form>
      </section>
      {modalAvisoCancelar && <ModalAvisoCancelar titulo={"Certeza que deseja cancelar o envio do comunicado?"} processando={processando} funcaoSubmit={handleCancelarComunicado} onClose={setModalAvisoCancelar} />}
      {modalAdicionarContato && <ModalAdicionarContato titulo={"Selecione o "} processando={processando} funcaoSubmit={handleAdicionarContato} onClose={setModalAdicionarContato} contatosAdicionados={contatos} candidatosAdicionados={candidatos} setContatoSelecionado={setContatoSelecionado} setCandidatoSelecionado={setCandidatoSelecionado} comunicado={comunicado} candidatoSelecionado={candidatoSelecionado} contatoSelecionado={contatoSelecionado} />}
    </div>
  );
}

export default ModalComunicado;
